export const ALL_LANGS = ['en', 'pt', 'es', 'fr', 'it', 'ro'] as const;
export const DEFAULT_LANG = ALL_LANGS[0];

export const COLORS = {
  paleBlue: '#64A1BB',
  darkBlue: '#003E52',
  green: '#3DAE2B',
  darkGreen: '#3B693D',
  orange: '#FC9948',
  almostWhite: '#F0F0F0',
  blue: '#67B9E8',
  lightGreen: '#93D500',
  yellow: '#FBC900',
  white: '#FFFFFF',
  almostBlack: '#333333',
  grey: '#828382',
} as const;

export const DISABLED_HERO = process.env.GATSBY_DISABLED_HERO === 'true';

export const LANGUAGE_SELECTOR_ENABLED = process.env.GATSBY_LANGUAGE_SELECTOR_ENABLED === 'true';

export const DISABLED_MODULES = process.env.GATSBY_DISABLED_MODULES
  ? JSON.parse(process.env.GATSBY_DISABLED_MODULES)
  : [];

export const DEFAULT_SITE_DOMAIN = 'ascenza.com';
export const DEFAULT_SITE_BASE_URL = 'https://www.ascenza.com';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;
