import React from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';

import * as styles from './CheckboxField.module.scss';

interface CheckboxFieldProps {
  topLabel?: string;
  checkboxLabel: string | React.Element;
  name: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
  small?: boolean;
  className?: string;
}

const CheckboxField = ({
  topLabel,
  checkboxLabel,
  name,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  small,
  className,
}: CheckboxFieldProps): React.ReactElement => {
  return (
    <FormControl error={error} className={styles.container + (className ? ' ' + className : '')}>
      {topLabel && <FormLabel>{topLabel}</FormLabel>}
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            color="primary"
            name={name}
            checked={value}
            onChange={e => onChange(e.target.checked)}
            onBlur={onBlur}
            size={small ? 'small' : 'medium'}
          />
        }
        label={checkboxLabel}
      />
      {/* <FormHelperText className={!helperText ? styles.hiddenHelperText : undefined}>
        {helperText ? helperText : 'x'}
      </FormHelperText> */}
    </FormControl>
  );
};

export default CheckboxField;
