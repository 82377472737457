import React from 'react';

function langFieldToRaw(langField: string) {
  return '_raw' + langField[0].toUpperCase() + langField.substr(1);
}
export function localize(value: any, languages: Array<string>): any {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages));
  } else if (value !== null && typeof value === 'object') {
    if (/^SanityLocale/.test(value.__typename) || /^locale_/.test(value._type)) {
      const field = languages.find(lang => value[lang]);
      if (field && value[field]) {
        return localize(value[field], languages);
      }
      const foundField = languages.find(lang => value[langFieldToRaw(lang)]);
      const rawField = foundField && langFieldToRaw(foundField);
      if (rawField && value[rawField]) {
        return localize(value[rawField], languages);
      }
      return undefined;
    }
    return Object.keys(value).reduce((result: any, key: string) => {
      result[key] = localize(value[key], languages);
      return result;
    }, {});
  }
  return value;
}

export function replaceNewLinesWithBr(str: string): React.ReactElement {
  const splitStr = str.split('\n');
  const splitStrWithBrs = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithBrs.push(splitStr[i]);
    splitStrWithBrs.push(<br key={i}></br>);
  }
  splitStrWithBrs.pop();
  return <>{splitStrWithBrs}</>;
}
