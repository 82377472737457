import React from 'react';
import { RawPortableText, PortableTextBlock } from '../types';
import _slugify from 'slugify';
import { SITE_DOMAIN } from '../constants';
import { VersatileLink } from '../graphql-fragments/versatileLink';
import {
  PageBreadcrumbValues,
  PageBreadcrumbValuesByTypename,
} from '../graphql-fragments/pageBreadCrumbValues';
import { DataLayer } from '../types/globals';

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

export function fromEntries<A extends string, B>(arr: Array<[A, B]>): Record<A, B> {
  const obj: Record<A, B> = {} as Record<A, B>;
  for (const [key, value] of arr) {
    obj[key] = value;
  }

  return obj;
}

export function normalizeString(str: string) {
  let normalizedStr = str.toLowerCase();
  if (normalizedStr.normalize) {
    normalizedStr = normalizedStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return normalizedStr;
}

export function rAFTimeout(fn: () => void, delay = 0): void {
  if (!window.requestAnimationFrame) {
    setTimeout(fn, delay);
  }

  const start = new Date().getTime();

  function loop() {
    const current = new Date().getTime();
    const delta = current - start;

    if (delta >= delay) {
      fn();
    } else {
      window.requestAnimationFrame(loop);
    }
  }

  window.requestAnimationFrame(loop);
}

export const clsx = (...args: Array<string | false | null | undefined>): string =>
  args.filter(Boolean).join(' ');

export function getPortableTextAsString(rawPortableText: RawPortableText): string {
  return rawPortableText
    .filter(block => block._type === 'block')
    .map(block => (block as PortableTextBlock).children.map(child => child.text).join(''))
    .join('\n');
}

export function checkIsInternalUrl(url: string): boolean {
  if (url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(?:\/|$)`))) {
    return true;
  } else if (url.match(/^\w+:\/\//)) {
    return false;
  } else if (url.match(/^(tel|fax|mailto):/)) {
    return false;
  } else {
    return true;
  }
}

export function getInternalUrlPath(url: string): string {
  if (!checkIsInternalUrl(url)) {
    throw new Error('Called getInternalUrlPath with a non internal url: ' + url);
  }
  if (!url.startsWith('http')) {
    return url;
  }
  const match = url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(.*)`));
  if (match === null) {
    throw new Error('Got null match from supposedly internal url: ' + url);
  }
  const path = match[1];
  if (path === '') {
    return '/';
  }
  if (!path.startsWith('/')) {
    throw new Error("Url path should start with slash but doesn't: " + url);
  }

  return path;
}

export function getReferenceUrl(
  typeName: 'SanityNewsArticle',
  slug: string,
  contentTypeSlug: string,
): string;

export function getReferenceUrl(
  typeName: 'SanityPage',
  slug: string,
  contentTypeSlug?: never,
): string;

export function getReferenceUrl(
  typeName: 'SanityPage' | 'SanityNewsArticle',
  slug: string,
  contentTypeSlug?: string,
): string {
  switch (typeName) {
    case 'SanityPage':
      return '/' + slug;
    case 'SanityNewsArticle':
      return `/${contentTypeSlug!.trim().replace(/^\/+|\/+$/g, '')}/${slug}/`;
    default:
      throw new Error('typeName value not known: ' + typeName);
  }
}

export function formatBytes(bytes: number, decimalPlaces = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const log1024 = Math.floor(Math.log(bytes) / Math.log(1024));
  const unit = ['b', 'K', 'M', 'G', 'T', 'P'][log1024];
  if (!unit) {
    throw new Error('Could not find a unit for given bytes number: ' + bytes);
  }
  // Using parseFloat to remove trailing zeros
  return parseFloat((bytes / Math.pow(1024, log1024)).toFixed(decimalPlaces)) + unit;
}

export function hexToRgb(hex: string): [number, number, number] {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return hex
    .match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)!
    .slice(1, 4)
    .map(hh => parseInt(hh, 16)) as [number, number, number];
}

export function getSolidColorFromTransparentColorOverBaseColor(
  transparentColorHex: string,
  alpha: number,
  baseColorHex = '#ffffff',
): string {
  const [tR, tG, tB] = hexToRgb(transparentColorHex);
  const [bR, bG, bB] = hexToRgb(baseColorHex);
  const r = Math.floor(tR * alpha + bR * (1 - alpha));
  const g = Math.floor(tG * alpha + bG * (1 - alpha));
  const b = Math.floor(tB * alpha + bB * (1 - alpha));

  return '#' + ((r << 16) | (g << 8) | b).toString(16);
}

export function getMenuLinkClasses(
  currentPathname: string,
  linkUrl: string,
  selectedClass: string,
  disabledClass: string,
): string {
  let classes = '';
  if (currentPathname.startsWith(linkUrl)) {
    classes += ' ' + selectedClass;
    if (currentPathname.trim() === linkUrl.trim()) {
      classes += ' ' + disabledClass;
    }
  }
  return classes;
}

export function truncateText(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const strParts = str.match(/(^|[^A-zÀ-ú\-])+[A-zÀ-ú\-]+/g) || [];
  let truncateStr = '';
  for (const strPart of strParts) {
    if (truncateStr.length + strPart.length + 3 > maxLength) {
      break;
    }
    truncateStr += strPart;
  }
  return truncateStr + '...';
}

export function convertRToSuperscript(str: string): React.ReactElement {
  const strParts = str.split(/(®)/).filter(Boolean);
  const convertedStrParts = [];
  for (let i = 0; i < strParts.length; i++) {
    const part = strParts[i];
    if (part === '®') {
      convertedStrParts.push(<sup key={i}>®</sup>);
    } else {
      convertedStrParts.push(part);
    }
  }
  return <>{convertedStrParts}</>;
}

export function slugify(str: string): string {
  return _slugify(str.replace(/[%®]/g, ''), {
    lower: true,
    strict: true,
  });
}

export function replaceNewLinesWithBr(str: string): React.ReactElement {
  const splitStr = str.split('\n');
  const splitStrWithBrs = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithBrs.push(splitStr[i]);
    splitStrWithBrs.push(<br key={i}></br>);
  }
  splitStrWithBrs.pop();
  return <>{splitStrWithBrs}</>;
}

export function getUrlFromVersatileLink(versatileLink: VersatileLink): string {
  const url = versatileLink.pageReference
    ? '/' +
      versatileLink.pageReference.slug.current +
      (versatileLink.anchorReference ? '#' + slugify(versatileLink.anchorReference) : '')
    : versatileLink.url;
  return url;
}

export function getFirstModuleBreadcrumbValuesByFunction<
  ExtraModuleProperties extends Record<string, unknown>,
>(
  pageBreadcrumbValues: Array<PageBreadcrumbValues<ExtraModuleProperties>>,
  moduleTester: (module: { __typename: string } & ExtraModuleProperties) => boolean,
): { url: string; name: string } | null {
  for (const page of pageBreadcrumbValues) {
    for (const section of page.sections) {
      for (const module of section.modules) {
        if (moduleTester(module)) {
          return {
            url: '/' + page.slug.current + '#' + slugify(section.name),
            name: page.title,
          };
        }
      }
    }
  }
  return null;
}

export function getFirstModuleBreadcrumbValuesByTypename(
  pageBreadcrumbValues: Array<PageBreadcrumbValuesByTypename>,
  typename: string,
): { url: string; name: string } | null {
  return getFirstModuleBreadcrumbValuesByFunction(
    pageBreadcrumbValues,
    module => module.__typename === typename,
  );
}

export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Nodash functions

type StringIteratee<EntryType> = string | ((arg0: EntryType) => string);
type GenericIteratee<EntryType, CompareType> = string | ((arg0: EntryType) => CompareType);

type SortOrder = 'asc' | 'desc';
type CompareFunction<CompareType> = (a: CompareType, b: CompareType) => number;

export function mapBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Partial<Record<string, EntryType>> {
  const result: Partial<Record<string, EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (key in result) {
      throw new Error(
        `Found duplicate key ${key} for iteratee ${iteratee} on array ${JSON.stringify(array)}`,
      );
    }
    result[key] = value;
  }
  return result;
}

export function groupBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Record<string, Array<EntryType>> {
  const result: Record<string, Array<EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (!(key in result)) {
      result[key] = [];
    }
    result[key].push(value);
  }
  return result;
}

function getComparisonScore<EntryType, CompareType>(
  a: EntryType,
  b: EntryType,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): number {
  const aValue =
    typeof iteratee === 'function' ? iteratee(a) : ((a as any)[iteratee] as CompareType);
  const bValue =
    typeof iteratee === 'function' ? iteratee(b) : ((b as any)[iteratee] as CompareType);
  if (typeof compareFuncOrOrder === 'function') {
    return compareFuncOrOrder(aValue, bValue);
  }

  const sortDirectionMul = compareFuncOrOrder === 'desc' ? -1 : 1;
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return sortDirectionMul * aValue.localeCompare(bValue);
  } else if (typeof aValue === 'number' && typeof bValue === 'number') {
    return sortDirectionMul * (aValue - bValue);
  } else if (aValue instanceof Date && bValue instanceof Date) {
    return sortDirectionMul * (aValue.getTime() - bValue.getTime());
  } else {
    throw new Error(
      'compareFuncOrOrder was not provided as a function but values to getComparisonScore are not string or numbers',
    );
  }
}

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType>;

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  sortOptionsArray: Array<
    | GenericIteratee<EntryType, unknown>
    | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
  >,
): Array<EntryType>;

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee:
    | GenericIteratee<EntryType, CompareType>
    | Array<
        | GenericIteratee<EntryType, unknown>
        | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
      >,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType> {
  const sortOptionsArray = Array.isArray(iteratee)
    ? iteratee
    : [[iteratee, compareFuncOrOrder] as const];
  return [...array].sort((a, b) => {
    for (const sortOptions of sortOptionsArray) {
      const [iteratee, compareFuncOrOrder] = Array.isArray(sortOptions)
        ? sortOptions
        : [sortOptions];
      const comparisonScore = getComparisonScore(a, b, iteratee, compareFuncOrOrder);
      if (comparisonScore !== 0) {
        return comparisonScore;
      }
    }
    return 0;
  });
}

export function flatten<EntryType>(arrayOfArrays: Array<Array<EntryType>>): Array<EntryType> {
  return arrayOfArrays.reduce((acc, val) => acc.concat(val), []);
}

export function uniqBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
): Array<EntryType> {
  const set = new Set();
  const uniqArray = [];
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as CompareType);
    if (!set.has(key)) {
      set.add(key);
      uniqArray.push(value);
    }
  }
  return uniqArray;
}

export function uniq<EntryType>(array: Array<EntryType>): Array<EntryType> {
  return [...new Set(array)];
}

export function range(start: number, end?: number): Array<number> {
  if (end === undefined) {
    end = start;
    start = 0;
  }
  const result = [];
  if (end < start) {
    for (let n = start; n > end; n--) {
      result.push(n);
    }
  } else {
    for (let n = start; n < end; n++) {
      result.push(n);
    }
  }
  return result;
}

export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  const arrCopy = [...arr];
  if (index > -1) {
    arrCopy.splice(index, 1);
  }
  return arrCopy;
}

// TEST FUNCTION

export function test<Result>(testName: string, f: () => Result, expectedResult: Result): void {
  try {
    const result = f();
    if (result === expectedResult) {
      console.log(`Test ${testName}: OK`);
    } else {
      console.log(`Test ${testName}: FAILED.\nExpected: ${expectedResult}\nGot ${result}`);
    }
  } catch (e) {
    console.log(`Test ${testName}: ERROR:`);
    console.error(e);
  }
}
