// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-news-article-page-tsx": () => import("./../../../src/templates/NewsArticlePage.tsx" /* webpackChunkName: "component---src-templates-news-article-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pages-photos-tsx": () => import("./../../../src/templates/pages/photos.tsx" /* webpackChunkName: "component---src-templates-pages-photos-tsx" */),
  "component---src-templates-pages-videos-tsx": () => import("./../../../src/templates/pages/videos.tsx" /* webpackChunkName: "component---src-templates-pages-videos-tsx" */)
}

