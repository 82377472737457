// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var button = "ListPagination-module--button--AcRqN";
export var container = "ListPagination-module--container--FGzO0";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var disabled = "ListPagination-module--disabled--1Krx0";
export var ellipsis = "ListPagination-module--ellipsis--Xkvj-";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconLeft = "ListPagination-module--iconLeft--oMyk8";
export var iconRight = "ListPagination-module--iconRight---WJaI";
export var label = "ListPagination-module--label--4wMG9";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var numbers = "ListPagination-module--numbers--SpgLR";
export var orange = "#fc9948";
export var pageNumber = "ListPagination-module--pageNumber--J9pTO";
export var paleBlue = "#64a1bb";
export var selected = "ListPagination-module--selected---beQ-";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";