import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { rAFTimeout } from './utils';

import LoadMoreButton from '../components/LoadMoreButton';
import ListPagination from '../components/ListPagination';

import { ALL_LANGS } from '../constants';
import { localize } from './sanity';

export function useAnimateInRef<T extends HTMLElement>(
  className: string,
  delay: number,
): React.RefObject<T> {
  const elRef = useRef<T>(null);
  useEffect(() => {
    rAFTimeout(() => elRef.current && elRef.current.classList.add(className), delay);
  }, []);
  return elRef;
}

export function useOnLoadEffect(effectFunction: () => void): boolean {
  const [hasRunOnLoadEffect, setHasRunOnLoadEffect] = useState(false);
  useEffect(() => {
    effectFunction();
    setHasRunOnLoadEffect(true);
  }, []);
  return hasRunOnLoadEffect;
}

export function useWindowSize(): { width?: number; height?: number } {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState({});

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function usePagination<Item>(
  items: Array<Item>,
  itemsPerPage: number,
  onChangePageClick?: (pageNumber: number) => void,
): {
  currentPage: number;
  currentPageItems: Item[];
  getPageUrlPart: () => readonly [string, string | null];
  resetPagination: () => void;
  renderPagination: () => JSX.Element;
} {
  const [currentPage, setCurrentPage] = useState(1);
  const [nCardsVisible, setNCardsVisible] = useState(itemsPerPage);

  const currentPageItems = items.slice(
    (currentPage - 1) * nCardsVisible,
    currentPage * nCardsVisible,
  );

  const hasRunOnLoadEffect = useOnLoadEffect(() => {
    let initialPage = 1;
    const currentUrl = new URLSearchParams(window.location.search);
    const urlPageStr = currentUrl.get('page');
    if (urlPageStr && urlPageStr.match(/^\d+$/)) {
      const urlPage = parseInt(urlPageStr);
      const totalPages = Math.ceil(items.length / itemsPerPage);
      if (1 <= urlPage && urlPage <= totalPages) {
        initialPage = urlPage;
      }
    }
    setCurrentPage(initialPage || 1);
  });

  function getPageUrlPart(): readonly [string, string | null] {
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const page = totalPages > 1 ? currentPage + '' : null;
    return ['page', page];
  }

  const resetPagination = () => {
    setCurrentPage(1);
    setNCardsVisible(itemsPerPage);
  };

  const renderPagination = () => (
    <>
      {hasRunOnLoadEffect && nCardsVisible < items.length && (
        <LoadMoreButton
          onClick={() => {
            setNCardsVisible(nCardsVisible + itemsPerPage);
          }}
        ></LoadMoreButton>
      )}
      {hasRunOnLoadEffect && items.length > itemsPerPage && (
        <ListPagination
          currentPage={currentPage}
          totalItems={items.length}
          itemsPerPage={nCardsVisible}
          onPrevPageClick={() => {
            setCurrentPage(currentPage - 1);
            if (onChangePageClick) {
              onChangePageClick(currentPage - 1);
            }
          }}
          onNextPageClick={() => {
            setCurrentPage(currentPage + 1);
            if (onChangePageClick) {
              onChangePageClick(currentPage + 1);
            }
          }}
          onPageClick={(pageNumber: number) => {
            setCurrentPage(pageNumber);
            if (onChangePageClick) {
              onChangePageClick(pageNumber);
            }
          }}
        ></ListPagination>
      )}
    </>
  );

  return {
    currentPage,
    currentPageItems,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  };
}

export function useUpdateUrlFromFilters(
  getUrlParts: () => Array<readonly [string, string | null]>,
  dependencies: Array<unknown>,
): void {
  useEffect(() => {
    const newUrlStrParts: Array<string> = [];
    for (const [key, value] of getUrlParts()) {
      if (value) {
        newUrlStrParts.push(key + '=' + value);
      }
    }

    const finalUrl =
      newUrlStrParts.length > 0 ? '?' + newUrlStrParts.join('&') : window.location.pathname;

    window.history.replaceState('', '', finalUrl);
  }, dependencies);
}

export function useScrollY(): number | null {
  const [scrollY, setScrollY] = useState<number | null>(null);
  useLayoutEffect(() => {
    const getScrollY = () => window.requestAnimationFrame(() => setScrollY(window.scrollY));
    getScrollY();
    window.addEventListener('scroll', getScrollY);
    return () => window.removeEventListener('scroll', getScrollY);
  }, []);

  return scrollY;
}

export function useLocalization<T>(data): {
  i18n: typeof i18n;
  t: TFunction<'translation', undefined>;
  localizedData: T;
} {
  const { i18n, t } = useTranslation();

  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const localizedData: T = localize(data, langs);
  return {
    i18n,
    t,
    localizedData,
  };
}
