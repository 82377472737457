import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as styles from './GenericLink.module.scss';
import { checkIsInternalUrl, getInternalUrlPath } from '../utils/utils';
import LocalizedLink from './LocalizedLink';

interface GenericLinkPropsBase {
  noUnderline?: boolean;
  noExternalUrlIcon?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export interface GenericLinkPropsWithTo extends GenericLinkPropsBase {
  to: string;
  noStyle?: boolean;
  styleOnly?: never;
}

export interface GenericLinkPropsWithStyleOnly extends GenericLinkPropsBase {
  to?: never;
  noStyle?: never;
  styleOnly: true;
}

export type GenericLinkProps = GenericLinkPropsWithTo | GenericLinkPropsWithStyleOnly;

export default function GenericLink({
  noUnderline,
  noExternalUrlIcon,
  className,
  noStyle,
  onClick,
  children,
  ...restProps
}: GenericLinkProps): React.ReactElement {
  if (restProps.styleOnly) {
    return (
      <span
        className={
          styles.link +
          (noUnderline ? ' ' + styles.noUnderline : '') +
          (className ? ' ' + className : '')
        }
        onClick={onClick}
      >
        {children}
      </span>
    );
  } else if (checkIsInternalUrl(restProps.to)) {
    return (
      <LocalizedLink
        to={getInternalUrlPath(restProps.to)}
        onClick={onClick}
        className={
          (noStyle ? '' : styles.link) +
          (noUnderline ? ' ' + styles.noUnderline : '') +
          (className ? ' ' + className : '')
        }
      >
        {children}
      </LocalizedLink>
    );
  } else {
    return (
      <a
        className={
          (noStyle ? '' : styles.link + ' ' + styles.iconRight) +
          (noUnderline ? ' ' + styles.noUnderline : '') +
          (className ? ' ' + className : '')
        }
        href={restProps.to}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        {!noExternalUrlIcon && !noStyle && <FontAwesomeIcon icon="external-link-alt" />}
      </a>
    );
  }
}
