import React, { useState } from 'react';
import { Portal, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import '../icons';

import '../styles/style.scss';
import '../styles/typography.scss';
import { OpenTermsModalContext } from '../contexts/OpenTermsModalContext';

import * as styles from './PageWrapper.module.scss';
import { LocationContext } from '../contexts/LocationContext';

import * as styleVariables from './../styles/variables.module.scss';
import { getCookieValue, setCookie } from '../utils/cookies';
import GenericLink from './GenericLink';
import { graphql, useStaticQuery } from 'gatsby';
import CheckboxField from './CheckboxField';
import { useLocalization } from '../utils/hooks';
import { Trans } from 'react-i18next';

interface PageWrapperProps {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
}

interface LocalizedQueryData {
  sanityWebsite: {
    reference: {
      slug: {
        current: string;
      };
    };
  };
}

const theme = createTheme({
  palette: {
    primary: {
      main: styleVariables.darkBlue,
    },
    text: {
      secondary: styleVariables.darkGrey,
    },
  },
});

const COOKIE_NAME = 'DownloadTermsConsent';

const PageWrapper = ({ children, location }: PageWrapperProps): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          reference {
            slug {
              current
            }
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const termsLink = localizedData.sanityWebsite || {};

  const [urlToDownloadAfterTermsAccepted, setUrlToDownloadAfterTermsAccepted] = useState<
    string | null
  >(null);
  const [isCheckBoxClicked, setIsCheckboxClicked] = useState<boolean>(false);

  function openModalIfTermsNotAccepted(url: string) {
    if (getCookieValue('DownloadTermsConsent') === undefined) {
      setUrlToDownloadAfterTermsAccepted(url);
    } else {
      // @ts-ignore
      window.location = url;
    }
  }

  return (
    <OpenTermsModalContext.Provider value={openModalIfTermsNotAccepted}>
      <ThemeProvider theme={theme}>
        <LocationContext.Provider value={location}>{children}</LocationContext.Provider>
      </ThemeProvider>
      {urlToDownloadAfterTermsAccepted && (
        <Portal>
          <div
            className={styles.overlay}
            onClick={() => {
              setUrlToDownloadAfterTermsAccepted(null);
            }}
          >
            <div className={styles.modalWrapper} onClick={e => e.stopPropagation()}>
              <div className={styles.modalContainer}>
                <h3 className={styles.modalTitle}>
                  {t('terms_modal.title', 'Terms of content usage')}
                </h3>
                <p className={styles.modalText}>
                  <Trans
                    i18nKey="terms_modal.intro_text"
                    defaults="To be able to download contents, you must accept our <termsLink>Terms of content usage</termsLink>. You only need to do it once now, but in a few months you might be required to review these Terms again."
                    components={{
                      termsLink: (
                        <GenericLink
                          to={'/' + termsLink.reference.slug.current}
                          onClick={() => setUrlToDownloadAfterTermsAccepted(null)}
                          className={styles.genericLink}
                        >
                          _
                        </GenericLink>
                      ),
                    }}
                  />
                </p>
                <div className={styles.checkboxContainer}>
                  <CheckboxField
                    checkboxLabel={
                      <Trans
                        i18nKey="terms_modal.agree_text"
                        defaults="I have read and agree with ASCENZA <termsLink>Terms of content usage</termsLink>."
                        components={{
                          termsLink: (
                            <GenericLink
                              to={'/' + termsLink.reference.slug.current}
                              onClick={() => setUrlToDownloadAfterTermsAccepted(null)}
                              className={styles.genericLink}
                            >
                              _
                            </GenericLink>
                          ),
                        }}
                      />
                    }
                    name="terms"
                    value={isCheckBoxClicked}
                    onChange={e => {
                      setIsCheckboxClicked(e);
                    }}
                  ></CheckboxField>
                </div>
                <div className={styles.buttonsRow}>
                  <button
                    className={styles.button + ' ' + styles.cancelButton}
                    onClick={() => {
                      setUrlToDownloadAfterTermsAccepted(null);
                    }}
                  >
                    {t('terms_modal.cancel_button', 'Cancel')}
                  </button>
                  <button
                    className={styles.button}
                    disabled={!isCheckBoxClicked}
                    onClick={() => {
                      setCookie(COOKIE_NAME, 'true', { expires: 3650, sameSite: 'strict' });
                      // @ts-ignore
                      window.location = urlToDownloadAfterTermsAccepted;
                      setUrlToDownloadAfterTermsAccepted(null);
                    }}
                  >
                    {t('terms_modal.save_button', 'Save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </OpenTermsModalContext.Provider>
  );
};

export default PageWrapper;
