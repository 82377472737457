import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './LoadMoreButton.module.scss';

interface LoadMoreButtonProps {
  onClick: () => void;
}

const LoadMoreButton = ({ onClick }: LoadMoreButtonProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <button className={styles.button} onClick={onClick}>
      {t('load_more_button.text', 'Load More')}
    </button>
  );
};

export default LoadMoreButton;
