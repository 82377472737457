import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './extracted/en/translation.json';
import ptTranslation from './extracted/pt/translation.json';
import esTranslation from './extracted/es/translation.json';
import frTranslation from './extracted/fr/translation.json';
import itTranslation from './extracted/it/translation.json';
import roTranslation from './extracted/ro/translation.json';
import { DEFAULT_LANG } from '../constants';

const resources = {
  en: {
    translation: enTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  it: {
    translation: itTranslation,
  },
  ro: {
    translation: roTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  returnEmptyString: false,
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
