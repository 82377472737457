import React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { getLocalizedUrlPath } from '../utils/i18n';

function LocalizedLink<TState>({
  to,
  ...restProps
}: Omit<GatsbyLinkProps<TState>, 'ref'>): React.ReactElement {
  const { i18n } = useTranslation();
  return <Link to={getLocalizedUrlPath(to, i18n.language)} {...restProps}></Link>;
}

export default LocalizedLink;
