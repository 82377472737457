import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faYoutube,
  faTwitter,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faExternalLinkAlt,
  faInfoCircle,
  faMapMarkerAlt,
  faPlayCircle,
  faPowerOff,
  faQuoteLeft,
  faSearch,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';

library.add(
  faYoutube,
  faTwitter,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faCalendar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faExternalLinkAlt,
  faInfoCircle,
  faMapMarkerAlt,
  faPlayCircle,
  faPowerOff,
  faQuoteLeft,
  faSearch,
  faCheck,
);
