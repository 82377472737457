/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// IE Polyfills
import 'url-search-params-polyfill';

import React from 'react';
import PageWrapper from './src/components/PageWrapper';

import './src/translation/i18n';

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper location={props.location}>{element}</PageWrapper>;
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }

  if (!('normalize' in String.prototype)) {
    String.prototype.normalize = function () {
      return this + '';
    };
  }
};
